#footer-section {
  background: linear-gradient(to top, #000 0%, #161c4c 100%);
  position: relative;
  top: 0px;
}
  
.footer-title {
  color: white;
  font-weight: 800;
}

.footer-img {
  margin-top: 40px;
  width: 150px;
  height: 150px;
}

.footer-phone, .footer-email {
  color: white;
  font-size: larger;
}

.footer-local {
  color: white;
}

.footer-local a:hover {
  color: var(--primary);
}

.footer-local .fa {
  position: relative;
  padding-right: 1em;
}

.footer-services,
.footer-links {
  position: relative;
  left: -1em;
}

.footer-txt {
  color: white;
  text-align: left;
}

.footer-txt .nav-link:hover {
  color: #00B0FF;
}

.copyright-footer {
  color: white;
  padding: 10px;
}

.copyright-section {
  padding-top: 5em;
}

.copyright-section hr {
  color: white;
}

.copyright-section a {
  background-color: #fff;
  color: #000;
  padding: 0.5em;
  border-radius: 50%;
}

.copyright-section a i {
  width:16px;
  height: 16px;
  text-align:center;
}

.copyright-section a:hover { 
  color: var(--primary);
}

.copyright-section ul {
  padding-top: 0.5em;
}

.footer-top {
  position: relative;
}

@media screen and (max-width:940px) {

  .copyright-section {
    margin-bottom: -5em;
    font-size: 0.8em;
  }

  .copyright-txt {
    position: relative;
    left: 1em;
  }

  .footer-services,
  .footer-links {
    position: relative;
    left: 1em;
    padding-bottom: 2em;
  }

  .footer-contact {
    position: relative;
    left: 1em;
  }

  .footer-img {
    margin-top: 0em;
    margin-bottom: 4em;
    position: relative;
    left: 1em;
  }

}