.reviews-section {
    max-width: 1300px;
    margin: 0 auto;
}

.reviews-section h1 {
    text-align: center;
}

.reviews-section .pricing-header p {
    line-height: 1.7em;
    font-size: 1.3em;
    color: #6c757d;
    margin: 0 auto;
    width: 60%;
    text-align: center;
}

.mucc-review {
    padding-bottom: 4em;
}

.reviews-mucc {
    width: 400px;
    height: 400px;
}

.reviews-mucc img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 3px solid var(--primary);
} 

.reviews-section .eachdiv {
    box-shadow: 0px 0px 0px #fff;
}

#reviews-bottom-section {
    content: "";
    position: relative;
    display: block;
    padding-bottom: 10em;
}

#reviews-bottom-section .why-una {
    width: 35%;
    margin: 0 auto;
}

#reviews-bottom-section p {
    width: 50%;
    text-align: center;
    font-size: 1.3em;
    color: #6c757d;
    margin: 0 auto;
}

.mucc-review h4 {
    font-size: 2em;
    font-weight: 800;
    font-style: italic;
    width: 90%;
}

.mucc-review .fa-quote-right {
    position: relative;
    left: 2em;
}

.mucc-review .name {
    font-weight: 700;
    position: relative;
    left: -2.5em;
}

.mucc-review .designation {
    position: relative;
    left: 4em;
    color: #6c757d;
    font-size: 0.9em;
    margin-top: -1em;
}

@media screen and (max-width:940px) {

    .reviews-section .imgbox {
        margin-bottom: 1.5em;
    }

    .imgbox {
        margin-bottom: 1.5em;
    }

    .mucc-review .fa-quote-right {
        position: relative;
        left: 1.2em;
    }

    .mucc-review {
        position: relative;
        top: -4em;
        margin-bottom: -4em;
    }

    .mucc-review h4 {
        font-size: 1.5em;
        font-weight: 800;
        font-style: italic;
        width: 100%;
        position: relative;
        top: -2em;
    }

    .mucc-review .name {
        font-weight: 700;
        position: relative;
        left: 0em;
        top: 1em;
    }
    
    .mucc-review .designation {
        position: relative;
        left: 0em;
        color: #6c757d;
        font-size: 0.9em;
        margin-top: -2em;
    }

    .mucc-review .row {
        position: relative;
        top: -2em;
    }

    .reviews-mucc {
        position: relative;
        left: 0.6em;
        margin-bottom: -5em;
    }

    #reviews-bottom-section {
        content: "";
        position: relative;
        display: block;
        padding-bottom: 20em;
    }

    #reviews-bottom-section .why-una {
        width: 60%;
        margin: 0 auto;
    }

    #reviews-bottom-section p {
        width: 80%;
        text-align: center;
        font-size: 1.3em;
        color: #6c757d;
        margin: 0 auto;
    }

    #reviews-page .services-header-title {
        font-size: 2em;
    }

}