.pricing-body h1 {
    text-align: center;
}

.pricing-body p {
    line-height: 1.7em;
    font-size: 1.3em;
    color: #6c757d;
    margin: 0 auto;
    width: 70%;
    text-align: center;
}

.pricing-body a {
    color: var(--primary);
    transition: 0.2s ease-in-out;
}

.pricing-body a:hover {
    color: #4fbdf0;
}

.pricing-header {
    padding-bottom: 6em;
}

.pricing-header .switch-body {
    width: 400px;
    margin: 0 auto;
    margin-top: -2em;
}

.pricing-header .switch-button {
    transform: none;
    margin: auto;
}

.pricing-body .card {
    border: none;
    border-radius: 1.5em;
    box-shadow: 5px 5px 20px #cecdcd6b;
    margin: 0 auto;
}

.pricing-body {
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 2em;
}

.pricing-body .label {
    background-color: #00aeff38;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-bottom: 1em;
}

.pricing-body .label h1 {
    padding-top: 0.5em;
    padding-bottom: 0.3em;
    font-size: 1.1em;
    font-weight: 800;
}

.pricing-body .main-plan {
    position: relative;
    top: -2em;
    border: 1.5px solid var(--primary);
}
.pricing-body .main-plan .label {
    background-color: var(--primary);
}

.pricing-body .main-plan .label h1 {
    color: #fff;
}

.pricing-body .plan h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
}

.pricing-body .main-plan .plan h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 3em;
}

.pricing-body .plan .month {
    color: rgb(161, 161, 161);
    font-size: 0.45em;
    padding-left: 0.5em;
}

.pricing-body .plan p {
    width: 100%;
    text-align: left;
    padding: 1em;
    font-size: 1.1em;
}

.pricing-body .plan h2 {
    font-size: 1.1em;
    font-weight: 800;
    padding: 1em;
}

.pricing-body .plan .plan-button .main-btn-blue {
    width: 200px;
    position: relative;
    margin: 0 auto;
    margin-bottom: 1em;
    left: 5.5em;
}

.pricing-body .main-plan .plan .plan-button .main-btn-blue {
    margin-bottom: 2em;
    background-color: var(--primary);
    color: #fff;
}

.pricing-body .main-plan .plan .plan-button .main-btn-blue:hover {
    background-color: #00aeffbc;
}

.pricing-body .fa-check-circle {
    font-size: 1.2em;
    padding-right: 0.5em;
    color: var(--primary);
}

.pricing-body ul {
    font-size: 1.1em;
}

#bottom-pricing-section {
    margin: 0 auto;
    margin-top: 5em;
    width: 100%;
}

#bottom-pricing-section .why-una {
    margin: 0 auto;
    width: 40%;
}

#bottom-pricing-section p { 
    width: 60%;
}

#pricing-link {
    color: var(--primary);
}

@media screen and (min-width:1500px) {
    .pricing-body {
        margin-bottom: 200px;
    }
}

@media screen and (max-width:940px) { 
    .pricing-body {
        max-width: 380px;
        margin-bottom: 14em;
    }

    .pricing-header {
        margin: 0 auto;
        margin-bottom: -8em;
    }

    .pricing-header h1 {
        font-size: 2em;
    }

    .pricing-header p {
        width: 350px;
    }

    .pricing-header .switch-body {
        width: 400px;
        left: -55%;
        position: relative;
    }

    .pricing-body .main-plan {
        top: 0em;
    }

    .pricing-body .card {
        margin-bottom: 2em;
    }

    .pricing-body .pricing-section {
        margin-bottom: -4em;
    }

    #bottom-pricing-section .why-una {
        margin: 0 auto;
        width: 85%;
        font-size: 2.3em;
    }

    #bottom-pricing-section p {
        width: 86%;
    }

}