#contact-wrapper {
  width: 1400px;
  margin: 0 auto;
}
  
@media screen and (max-width:940px) {
  #contact-wrapper {
    width: 380px;
    margin: 0 auto;
    margin-top: -80px;
  }
}

@media screen and (max-width:1200px) {
  #contact-wrapper {
    margin-bottom: 200px;
  }
}

@media screen and (max-width:800px) {
  #contact-wrapper {
    margin-bottom: 100px;
  }
}

@media screen and (min-width:1500px) {
  #contact-wrapper {
    margin-bottom: 400px;
  }

  #contactBtnDark {
    position: relative;
    z-index: 99;
  }

}

  
  .contact-txt {
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
  }
  
  .form-group .form-control {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #00B0FF;
  }
  
  .form-group .form-label {
    font-size: 20px;
    font-weight: bold;
  }
  
  #FormControlTextarea {
    font-size: 20px;
    font-weight: bold;
    background-color: rgb(240, 240, 240);
    border: none;
    border-bottom: 2px solid #00B0FF;
  }
  
  .contact-btn {
    display: block;
    font-size: 18px;
    max-width: 190px;
    border: 0;
    text-decoration: none;
    border-radius: 4px;
    padding: 20px 30px;
    background-color: white;
    transition: 0.7s cubic-bezier(0.39, 0.5, 0.15, 1.36);
    color: #2c3892;
    box-shadow: #2c3892 0 0 0 4px inset;
    float: right;
    font-weight: bold;
    border-radius: 50px;
  }
  
  .contact-btn:hover {
    color: #fff;
    box-shadow: #2c3892 0 0 0 40px inset;
  }

  #contact-section {
    background: linear-gradient(to top, #161c4c 0%, #161c4c 100%);
    height: 400px;
  }

  #contact-section .home-btn a {
    color: #fff;
  }

  .home-contact-txt {
    font-size: 2.8em;
    font-weight: bold;
    text-align: center;
    margin: 0;
    color: #fff;
    z-index: 99;
    position: relative;
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;  */
  }

  .contact-home-btn {
    display: block;
    font-size: 18px;
    border: 0;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    padding: 15px;
    background-color: white;
    transition: 0.7s cubic-bezier(0.39, 0.5, 0.15, 1.36);
    margin: auto;
    font-weight: bold;
    color: #fff;
    box-shadow: #2c3892 0 0 0 40px inset;
    border-radius: 50px;
    padding: 20px 30px;
    position: relative;
    
  }
  
  .contact-home-btn:hover {
    box-shadow: #00B0FF 0 0 0 4px inset;
    background-color: white;
  }

  .contact-home-btn a {
    color: white;
  }

  .contact-home-btn:hover a {
    color: #00B0FF;
  }
  .contact-home-btn:hover {
    color: #00B0FF;
  }

.get-in-touch {
  overflow: hidden;
  position: relative;
  padding: 0 0 70px;
  margin-bottom: -80px;
}

.get-in-touch.rotated-bg:before {
    content: '';
    position: absolute;
    width: 400px;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 800px;
    left: -10%;
    top: 100%;
    z-index: -1;
    border-radius: 300px;
    background-color: #def4ff;
}

.contact-left {
  /* border: 2px solid red; */
}

.contact-left h1 {
  font-size: 5em;
  text-align: left;
}

.contact-left p {
  width: 80%;
  font-size: 1.2em;
}

.contact-left ul {
  padding-top: 20px;
  padding-bottom: 20px;
  float: left;
  position: relative;
  left: -30px;
}

.contact-left ul a {
  border: 2px solid #00B0FF;
  border-radius: 35px;
  padding: 20px;
  font-size: 1.5em;
  color: #00B0FF;
  transition: 0.2s;
}

.contact-left ul a:hover {
  color: white;
  background-color: #00B0FF;

}

/* MAP */

#contact-map {
  height: 70%;
  width: 100%;
}

#contact-page .mobile-btn-tutoring {
  position: relative;
  left: 22em;
}

@media screen and (max-width:940px) {
  #contact-map {
    margin-top: 60px;
    height: 500px;
    width: 95%;
    position: relative;
    left: 10px;
  } 

  .contact-left {
    position: relative;
    top: -60px;
  }

  .contact-left p {
    width: 80%;
  }

  #contact-section {
    position: 
    relative;
  }

  #contact-page .mobile-btn-tutoring {
    position: relative;
    left: -9em;
  }

  .jotform {
    margin-top: -5em;
  }
}