html {
  overflow-x: hidden;
}

#hero-page {
  background-color: #FCFCFF;
}

.hero .col-lg-6 {
  padding-left: 120px;
  padding-right: 120px;
}

.hero-project-body {
  position: relative;
  padding-top: 200px;
  width: 100%;
  height: 100%;
  z-index: 1;
  animation: 0.5s ease-out 0s 1 slideInRight;
}

.hero-project-body img {
  height: 100%;
  width: 95%;
}

.centered {
  padding-left: 50px;
}

.hero-company-name {
  animation: 0.5s ease-out 0s 1 slideInRight;
  text-align: left;
  color: black;
  font-size: 1.2vw;
  font-weight: 900;
  text-transform: uppercase;
  opacity: 0;
}

.hero-company-name:before {
  background-color: #00B0FF;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 5%;
}

.hero-company-name:before {
	right: 0.5em;
	margin-left: 2%;
}


.homepage-all-text {
  padding-top: 12em;
  position: relative;
  z-index: 9;
  padding-left: 40px;
}

#logo_bg_body {
  position: relative;
  top: -350px;
  margin-bottom: -450px;
  opacity: 0.5;
  float: right;
  z-index: 0;
}
#logo_bg_body img {
  width: 600px;
  height: 600px;
}

#home-test-header-text {
  font-size: 1.1em;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}

#home-services-header-text {
  font-size: 1.1em;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #00B0FF;
}


.hero-company-name span {
  color: #00B0FF;
}

.hero-title {
  /* animation: 0.5s ease-out 0s 1 slideInLeft; */
  color: black;
  background-color: transparent;
  font-size: 5vw;
  text-align: left;
  position: relative;
  top: -20px;
  font-weight: 900;
  letter-spacing: 0.0rem;
  text-transform: uppercase;
}

.hero-title span {
  font-family: Arial, Helvetica, sans-serif;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.homepage-all-text:before {
  display: block;
  position: absolute;
  content: '';
  background: linear-gradient(to right, #2e9aed 0%, #FCFCFF 150%);
  height: 60em;
  width: 60em;
  z-index: -1;
  top: -10em;
  border-radius: 50%;
  transform: rotate(-25deg);
  left: -30em;
  animation: 0.4s ease-out 0s 1 slideInLeft;
}

.homepage-all-text::after {
  display: block;
  position: relative;
  content: '';
  background: linear-gradient(to right, #60b1f0 0%, #FCFCFF 150%);
  height: 10em;
  width: 10em;
  z-index: -3;
  top: -25em;
  border-radius: 50%;
  transform: rotate(-25deg);
  left: 24em;
  animation: 0.3s ease-out 0s 1 slideInRight;
}

.hero-project-body::after {
  display: block;
  position: absolute;
  content: '';
  background: linear-gradient(to right, #60b1f0 0%, #FCFCFF 150%);
  height: 15em;
  width: 15em;
  z-index: -1;
  top: -5em;
  border-radius: 50%;
  transform: rotate(-25deg);
  right: -6em;
}

.hero-txt {
  animation: 0.5s ease-out 0s 1 slideInRight;
  background-color: transparent;
  position: relative;
  top: -50px;
  text-align: left;
}

#home-check {
  padding-top: 40px;
  width: 100%;
  float: left;
  text-align: left;
  color: black;
  font-size: 1.6vw;
}

#home-check p {
  line-height: 2.3vw;
}

/* #home-check p span {
  font-family: Arial, Helvetica, sans-serif;
  color: transparent;
  -webkit-text-stroke-width: 0.8px;
  -webkit-text-stroke-color: black;
  font-style: italic;
  font-size: 2vw;
} */

.home-btn-body {
  float: left;
  position: relative;
}

@media screen and (max-width:940px) { 

  #home-check {
    padding-top: 60px;
    width: 100%;
    float: none;
  }

  #home-check p {
    line-height: 7vw;
    text-align: left;
    padding-left: 10px;
  }

  .home-btn-body {
    float: none;
    left: 0%;
  }

  .hero-project-body {
    margin-bottom: -80px;
    z-index: 1;
  }
}

.home-btn-body a {
  margin-top: -80px;
  float: left;
}

.home-btn {
  display: block;
  font-size: 18px;
  border-color: #00B0FF;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  padding: 15px;
  transition: 0.7s cubic-bezier(0.39, 0.5, 0.15, 1.36);
  margin: auto;
  font-weight: bold;
  color: white;
  box-shadow: #2e9aed2e 0 0 0 4px;
  border-radius: 50px;
  color: #fff;
  background-color: transparent;
}

.home-btn .fa {
  padding-left: 1em;
}

.home-btn:hover {
  color: white;
  background-color: #00B0FF;
}

.check {
  color: #00B0FF;
  background-color: transparent;
  font-size: 35px;
}

#bg_pattern {
  width: 100%;
  height: 100%;
}

#bg_pattern img {
  width: 10%;
  height: 10%;
  position: relative;
  left: 48%;
  top: 4.5em;
  margin-top: -100px;
  opacity: 0.3;
  border-radius: 50%;
}

#process-wrapper .row #bg_pattern img {
  left: -4%;
}

.portfolio-body #bg_pattern img,
#services-wrapper .container #bg_pattern img,
#whatclients #bg_pattern img {
  left: 45%;
}

.fa-quote-right {
  color: #00B0FF;
  transform: scale(2);
  text-align: center;
  margin-bottom: -1em;
  left: 1em;
  opacity: 0.12;
  position: relative;
}

#una-web {
  position: absolute;
  margin-top: 500px;
  margin-left: -700px;
  color: #fff;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  font-size: 140px;
  font-weight: 900;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display:inline-block;
  display: none;
}

#una-web2 {
  position: absolute;
  margin-top: 2100px;
  margin-left: -700px;
  color: #fff;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  font-size: 140px;
  font-weight: 900;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display:inline-block;
  display: none;
}

#una-web3 {
  z-index: -1;
  position: absolute;
  margin-top: 3700px;
  margin-left: -700px;
  color: #fff;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  font-size: 140px;
  font-weight: 900;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display:inline-block;
  display: none;
}

#una-web-about {
  position: absolute;
  margin-top: 650px;
  margin-left: -700px;
  color: #fff;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  font-size: 140px;
  font-weight: 900;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display:inline-block;
  display: none;
}

#una-web2-about {
  z-index: -1;
  position: absolute;
  margin-top: 2250px;
  margin-left: -700px;
  color: #fff;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  font-size: 140px;
  font-weight: 900;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display:inline-block;
  display: none;

}

#una-web-contact {
  z-index: -1;
  position: absolute;
  margin-top: 650px;
  margin-left: -700px;
  color: #fff;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
  font-size: 140px;
  font-weight: 900;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  display:inline-block;
  display: none;
}

@media screen and (max-width:940px) { 
  #una-web,
  #una-web2,
  #una-web3,
  #una-web-about,
  #una-web2-about,
  #una-web-contact {
    display: none;
  }
}

#testimonial-card {
  transition: .4s ease-out;
  background: linear-gradient(#FCFCFF, #FCFCFF 50%, #2e9aed 50%, #2e9aed);
  background-size: 100% 200%;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
  transition: background 2s;
  border-radius: 20px;
}

#testimonial-card:hover {
  background-position: 100% 100%;
}

#testimonial-card .card-text {
  font-size: 0.9em;
  color: #00B0FF;
}

#testimonial-card cite {
  font-size: 0.9em;
}

#testimonial-card cite span {
  font-size: 1.2em;
  font-weight: 600;
}

#testimonial-card:hover .fa-quote-right,
#testimonial-card:hover #blockquote p {
  transition: 2s;
  color: white;
}

@media screen and (max-width:940px) { 
  #testimonial-card {
    width: 95%;
    left: 20px;
  }

  #testimonial-card .card-text {
    text-align: left;
  }
}

#home-process-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #def4ff;
}

@media screen and (max-width:940px) { 
  #home-process-section {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .homepage-all-text:before {
    display: block;
    position: absolute;
    content: '';
    height: 35em;
    width: 35em;
    z-index: -1;
    top: -10em;
    border-radius: 50%;
    transform: rotate(-25deg);
    left: -12em;
    }

    .homepage-all-text::after {
      position: absolute;
    }

    .hero-project-body::after {
      left: 18em;
      top: -8em;
    }
}

.cny_drones img {
  width: 100%;
  height: 100%;
}

#work-large {
  position: relative;
  left: 10%;
}

#home-portfolio-text {
  text-align: left;
}

#home-portfolio {
  background: linear-gradient(to top, #2e9aed 0%, #8fcaf7 100%);
  padding-top: 5em;
  max-width: 1800px;
  margin: 0 auto;
}

#portfolio-section-2 {
  padding-bottom: 100px;
  max-width: 1400px;
  margin: 0 auto;
}

#portfolio-section-2 .main-btn-white {
  position: relative;
  left: 7em;
  border: 2px solid #fff;
}

#portfolio-section-2 .main-btn-white a {
  color: #fff;
}

#portfolio-section-2 .main-btn-white:hover:before{
  background-color: #fff;
}

#portfolio-section-2 .main-btn-white:hover a {
  color: #00B0FF;
}

#about-section .main-btn-white {
  position: relative;
  left: -7em;
  border: 2px solid #fff;
  padding: .1em 2em;
}

#about-section .main-btn-white a {
  color: #fff;
  font-size: 0.8em;
}

#about-section .main-btn-white:hover:before{
  background-color: #fff;
}

#about-section .main-btn-white:hover a {
  color: #00B0FF;
}

#contact-section .main-btn-white {
  position: relative;
  border: 2px solid #fff;
}

.contact-btn-home {
  width: 200px;
  margin: 0 auto;
}

#contact-section .main-btn-white a {
  color: #fff;
}

#contact-section .main-btn-white:hover:before{
  background-color: #fff;
}

#contact-section .main-btn-white:hover a {
  color: #00B0FF;
}

#work-large .cny_drones {
  position: relative;
  top: 50px;
}


#left-portfolio {
  position: relative;
  top: 10%;
  padding-left: 6em;
}

.portfolio-text-body {
  width: 100%;
  padding-left: 6em;
}

.portfolio-text-body p {
  font-size: 20px;
  color: #fff;
}

#portfolio-section-2 .home-about-header-text {
  font-size: 17.5px;
  padding-left: 1em;
}

#portfolio-section-2 #bg_pattern img {
    width: 8%;
    height: 10%;
    position: relative;
    left: 5%;
    top: 4.5em;
    margin-top: -100px;
    opacity: 0.3;
    border-radius: 50%;
}

.mobile-portfolio {
  display: none;
}

@media screen and (max-width:940px) { 
  .mobile-portfolio {
    display: block;
  }

  #portfolio-section-2 {
    /* display: none; */
  }

  .mobile-portfolio .portfolio-text-mobile {
    padding: 0px;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    top: -20px;
    font-size: 17.5px;
    text-align: center;
    color:#6c757d;
  }

  .mobile-portfolio #home-portfolio-text {
    padding: 40px;
  }


}


#home-hero-image-body {
  position: relative;
  z-index: 99;
  color: white;
  /* clip-path: inset(0); */
  height: 800px;
  background-color: black;
}

#home-hero-image-body::before {
  z-index: 1;
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: #000;
  opacity: 0.01;
  display: none;
}


#home-hero-image-body video {
  z-index: 0;
  width: 100%;
  -webkit-filter: brightness(70%); 
  filter: brightness(70%);
  background-repeat: no-repeat;
  object-fit: cover;
  /* position: fixed; */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%; 
  height: 100%;
}

#home-hero-image-body .mobile_img {
  z-index: 0;
  width: 100%;
  -webkit-filter: brightness(70%); 
  filter: brightness(70%);
  background-repeat: no-repeat;
  object-fit: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  display: none;
}

#home-hero-content {
  position: absolute;
  top: 55%;
  left: 40%;   
  /* -----------   For the first h1 below  */   
  /* left: 33%; */
  width: 65%;    
  max-width: 900px;
  margin: 0 auto; 
  /* -----------   For the first h1 below  */   
  /* width: 55%; */
  transform: translate(-50%, -50%);
  z-index: 100;
}

#home-hero-content .home-btn {
  position: relative;
  top: 1em;
  transition: 1.5s;
}

.home-hero-content-text {
  width: 100%;
  margin: 0 auto;
}

.home-hero-content-text h1 {
  /* -----------   For the first h1 below  */
  /* font-size: 7em;     */
  font-size: 8em;
  line-height: 1.05;
  font-weight: 700;
  padding-bottom: 0.6em;
  width: 900px;
  padding-right: 2em;
}

.hero-title {
  color: white;
}

#services-section .home-about-header-text {
  font-size: 17.5px;
  color: #00B0FF;
}

#home-services-section {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  z-index: 999;
  top: -5em;
  margin-bottom: -5em;
}

#home-services-section .home-services-cards .card {
  padding: 1em;
  border: none;
  border-radius: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  position: relative;
  filter: grayscale(0.9);
  transition: 0.4s ease-in-out;
  top: 0px;
}

#home-services-section .home-services-cards .card img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

#home-services-section .home-services-cards .card i {
  font-size: 4em;
  margin: 0 auto;
  float: right;
  position: relative;
  left: -2em;
  color: white;
  transition: 0.4s ease-in-out;
}

#home-services-section .home-services-cards .card-title {
  font-size: 22px;
  padding-left: 2em;
  padding-right: 2em;
  font-weight: 800;
  letter-spacing: 0.01em;
}

#home-services-section .home-services-cards .card:hover{
  filter: grayscale(0.0);
  transition: 0.4s ease-in-out;
  top: -10px;
}

#home-services-section .home-services-cards .card:hover i {
  color: #00B0FF;
  transition: 0.4s ease-in-out;
}

#home-services-section .home-services-cards .card-img-overlay {
  top: 40%;
}

#wave_bg {
  position: relative;
  z-index: -999;
  top: -12em;
  left: 24em;
  margin-bottom: -60em;
  transform: rotate(-30deg);
  opacity: 0.2;
  display: none;
}

#wave_bg img {
  width: 100%;
}

#wave_bg_2 {
  /* position: relative;
  z-index: -999;
  top: -12em;
  left: 24em;
  margin-bottom: -60em;
  transform: rotate(-30deg);
  opacity: 0.2; */
  opacity: 0.2;
  margin-top: -20em;
  margin-bottom: -10em;
  top: 4em;
  position: relative;
  left: 0em;
  transform: rotate(120deg);
  width: 20%;
  display: none;
}

#wave_bg_2 img {
  /* width: 100%; */
}

#services-section {
  position: relative;
  padding-bottom: 6em;
  top: -10em;
  margin-bottom: -10em;
}

#home-bg-section {
  max-width: 1800px;
  margin: 0 auto;
  padding-top: 3em;
}

#home-bg-section .home-bg-content-text {
  max-width: 900px;
}

#home-bg-content .home-bg-content {
  position: relative;
  z-index: 9999;
}

#home-bg-content .home-bg-content-text button a {
  color: #fff;
}

#home-bg-image-body {
  position: relative;
  z-index: 99;
  color: white;
  clip-path: inset(0);
  height: 800px;
  background-color: black;
}

#home-bg-image-body::before {
  z-index: 1;
  content: '';
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: #000;
  opacity: 0.5;
}


#home-bg-image-body img {
  z-index: 0;
  width: 100%;
  -webkit-filter: brightness(70%); 
  filter: brightness(70%);
  background-repeat: no-repeat;
  object-fit: cover;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%; 
  height: 100%;
}

#home-bg-content {
  position: absolute;
  top: 50%;
  left: 40%;   
  /* -----------   For the first h1 below  */   
  /* left: 33%; */
  width: 100%;     
  /* -----------   For the first h1 below  */   
  /* width: 55%; */
  transform: translate(-50%, -50%);
  z-index: 9;
}

#home-bg-content .main-btn-white {
  position: relative;
  top: 45%;
  left: 40%;
  float: right;
  transition: 1.5s;
}

.home-bg-content-text {
  width: 100%;
  margin: 0 auto;
}
.home-bg-content .home-about-header-text {
  font-size: 17.5px;
  font-weight: 600;
  color: #00B0FF;
}

.home-bg-content h3 {
  font-weight: bold;
  font-size: 2.5em;
}

.home-bg-content p {
  font-size: 20px;
}

.home-bg-content p span {
  font-weight: 800;
  font-style: italic;
  color: #00B0FF;
}

/* Fish Animation */
svg.fish{
  overflow:hidden;
}

@-webkit-keyframes swim
{
	0% {margin-left: -235px}
	90% {margin-left: 100%;}
	100% {margin-left: 100%;}
}

@keyframes swim
{
	0% {margin-left: -235px}
	70% {margin-left: 100%;}
	100% {margin-left: 100%;}
}

.fish{
	width: 235px;
	height: 104px;
	margin-left: -235px;
	position: absolute;	
	animation: swim 20s;
	-webkit-animation: swim 20s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
	animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
}

svg #fish1,
svg #fish2,
svg #fish3,
svg #fish4,
svg #fish5,
svg #fish6 {
fill:#164f7b;
opacity: 0.4;
  
    -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

svg #fish2{
	animation-delay: 0.5s;
	-webkit-animation-delay: 0.5s;
}

svg #fish3{
	animation-delay: 0.2s;
	-webkit-animation-delay: 0.2s;
}

svg #fish4{
	animation-delay: 0.4s;
	-webkit-animation-delay: 0.4s;
}

svg #fish5{
	animation-delay: 0.1s;
	-webkit-animation-delay: 0.1s;
}

svg #fish6{
	animation-delay: 0.3s;
	-webkit-animation-delay: 0.3s;
}

/**/
@-moz-keyframes bounce {
  0%, 50%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -moz-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
@-webkit-keyframes bounce {
  0%, 50%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%, 50%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25% {
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  75% {
    -moz-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}


.outerdiv
{
	width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1em;
}

.eachdiv
{
	padding: 1rem 1.5rem;
    border-radius: 0.8rem;
    box-shadow: 5px 5px 20px #6d6b6b6b;
}
.reviewbody
{
	background: #fff;
  height: 420px;
}

.imgbox
{
	margin-right: 1rem;
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.imgbox img
{
	border-radius: 50%;
	width: 2rem;
}
.detbox
{
	display: flex;
    flex-direction: column;
    justify-content: center;
}
.detbox p
{
	margin: 0;
}
.detbox .name
{
	color: black;
    font-size: 1em;
    margin-bottom: 0.1rem;
    font-weight: 600;
}

.detbox .designation {
	color: black;
  opacity: 50%;
  font-size: 0.8rem;
  padding-bottom: 1em;
  position: relative;
  left: 4.2em;
}

.detbox .designation.dark
{
	color: #49505A;
}
.review h4
{
	font-size: 1.2rem;
	color: black;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 0.8rem;
}

.fa-star {
  padding-right: 0.4em;
  color: #FFDF00;
}

.fade-in-up {
  opacity:0;
  visibility: hidden;

  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.fade-in-up-load {
  opacity:0;
  visibility: hidden;

  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  
  -webkit-transition: all 1.2s ease-in-out;
  transition: all 1.2s ease-in-out;
}

.fade-in-up-fast {
  opacity:0;
  visibility: hidden;

  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

#UNA_Image {
  display: none;
}

.before-after .before-after-img {
  width: 600px;
  height: 100%;
  margin: 0 auto;
}

@media screen and (max-width:940px) { 

  .hero {
    padding-top: 8em;
  }

  #UNA_Video {
    display: none;
  }

  #UNA_Image {
    display: block;
    position: relative;
    top: -350px;
    /* filter:contrast(1.3); */
    filter:brightness(1.4);
    opacity: 0.4;
  }

  .hero-title {
    /* top: -150px; */
    text-align: left;
    left: 5%;
  }

  .home-hero-content-text {
    position: relative;
    top: 4em;
  }

  #home-hero-section .main-btn-white {
    position: relative;
    top: 8em;
    left: 0.5em;
  }

  .home-hero-content-text h1 {
    font-size: 3.9em;
    padding-right: 0em;
    text-align: center;
    width: 350px;
    top: -0.8em;
    left: -0.25em;
  }

  .hero-company-name {
    text-align: center;
    font-size: 4vw;
    text-align: left;
  }

  .hero-company-name:before {
    /* display: none; */
    background-color: transparent;
  }

  .hero-txt {
    top: -350px;
    height: 50%;
    margin-bottom: -300px;
    /* display: none; */
  }

  #home-check,  #home-check p span {
    text-align: center;
    font-size: 6vw;
  }

  .home-btn-body {
    position: relative;
    top: -180px;
    left: 15%;
    margin-bottom: 2em;
  }

  .home-btn-body a {
    margin-top: -150px;
    float: none;
  }

  .home-btn {
    margin-bottom: -300px;
    margin-top: 1em;
  }

  #home-services-section .home-services-cards {
    margin-bottom: -10em;
  }

  #home-hero-image-body {
    height: 700px;
  }

  #home-services-section .home-services-cards .card {
    padding: 1em;
    border: none;
    border-radius: 0px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    position: relative;
    filter: grayscale(0.9);
    transition: 0.4s ease-in-out;
    top: -8em;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1.5em;
  }
  
  #home-services-section .home-services-cards .card img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    margin-bottom: -6em;
  }
  
  #home-services-section .home-services-cards .card i {
    font-size: 4em;
    margin: 0 auto;
    float: right;
    position: relative;
    left: -2em;
    color: #fff;
    transition: 0.4s ease-in-out;
  }
  
  #home-services-section .home-services-cards .card-title {
    font-size: 22px;
    padding-left: 2em;
    padding-right: 2em;
    font-weight: 800;
    letter-spacing: 0.01em;
  }
  
  #home-services-section .home-services-cards .card:hover{
    filter: grayscale(0.0);
    transition: 0.4s ease-in-out;
    top: -8em;
  }
  
  #home-services-section .home-services-cards .card:hover i {
    color: #00B0FF;
    transition: 0.4s ease-in-out;
  }
  
  #home-services-section .home-services-cards .card-img-overlay {
    top: 30%;
  }  

  #home-services-section .home-services-cards {
    padding-bottom: 8em;
  }

  #services-section .home-about-header-text {
    text-align: center;
  }

  #services-section {
    position: relative;
  }

  .homepage-all-text {
    padding-top: 120px;
    margin-bottom: -400px;
    padding-left: 0px;
  }

  .before-after .before-after-img {
    width: 400px;
    height: 100%;
    top: -3em;
    margin: 0 auto;
  }

  .before-after {
    margin-bottom: 6em;
  }

  svg.fish {
    display: none;
  }

  #home-bg-section .home-bg-content-text {
    width: 300px;
    margin: 0 auto;
    position: relative;
    left: 2em;
  }

  #home-bg-content .home-bg-content-text button {
    margin: 0 auto;
    position: relative;
    float: left;
    left: 0em;
  }

  .portfolio-text-body {
    width: 100%;
    padding-left: 2em;
  }

  #portfolio-section-2 .home-about-header-text {
    padding-left: 0em;
    position: relative;
    left: 0.5em;
  }

  .portfolio-text-body p {
    font-size: 20px;
    color: #fff;
    padding-bottom: -2em;
    margin-bottom: 8em;
    position: relative;
    left: -0.2em;
  }

  #about-section {
    padding-bottom: 2em;
  }

  #about-section .home-btn {
    position: relative;
    left: -5em;
    margin-top: -1em;
    border: 2px solid #fff;
    margin-bottom: -1em;
  }

  #about-section .home-btn a {
    color: #fff;
  }

  #about-section .home-btn:hover a{
    color: #00B0FF;
  }

  #about-section .home-btn:hover {
    background-color: #fff;
  }

  .reviewbody {
    height: 100%;
  }

  .reviewbody .fa-quote-right {
    position: relative;
    left: 1.2em;
    top: -1em;
  }

  .long-review .fa-quote-right {
    position: relative;
    top: -1em;
    left: 1em;
  }

  #portfolio-section-2 .home-btn {
    left: -4em;
    top: -10em;
    /* margin-bottom: 0em; */
  }

  .detbox .designation {
    left: 0em;
  }

  #portfolio-section-2 .main-btn-white {
    position: relative;
    top: -12em;
    left: 3em;
    margin-bottom: -20em;
  }

  #about-section .main-btn-white {
    position: relative;
    left: -3em;
  }

}
