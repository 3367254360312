.pagenotfound-section {
    padding: 3em;
    padding-top: 12em;
    padding-bottom: 8em;
    text-align: center;
}

.pagenotfound-section .pagenotfound-img {
    width: 100px;
    height: 100%;
    margin: 0 auto;
    border-radius: 50%;
    background-color: #def4ff;
}

.pagenotfound-section .pagenotfound-img img {
    width: 100px;
    height: 100px;
    position: relative;
}

.pagenotfound-section h1 {
    color: var(--primary);
    font-size: 6em;
    font-weight: 600;
}

.pagenotfound-section p {
    font-weight: 800;
}

.pagenotfound-section h2 {
    font-size: 1.2em;  
    color: #6c757d;
    font-weight: 400;
}

.pagenotfound-section button {
    margin-top: 2em;
}

.pagenotfound-section button a {
    color: var(--primary);
}

.pagenotfound-section button:hover a {
    color: #fff;
}

@media screen and (max-width: 900px) {

    .pagenotfound-section {
        padding-bottom: 24em;
        margin: 0 auto;
        overflow: hidden;
    }

}
