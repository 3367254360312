.services-top {
	position: relative;
  max-width: 1800px;
  margin: 0 auto;
  top: -150px;
}

.services-top-wave {
  position: relative;
  top: -30em;
  margin: 0 auto;
  margin-bottom: -30em;
  max-width: 1800px;
}

#second-row-tests {
  display: none;
}

#services-img {
	position: relative;
	top: 20px;
	width: 100%;
	height: 600px;
	object-fit: cover;
	filter: brightness(30%);
}

@media screen and (min-width:1600px) {
  .services-top {
    max-width: 2000px;
  }
  .services-top-wave {
    max-width: 2000px;
    top: -32em;
  }

}

.services-header-title {
	position: absolute;
	top: 50%;
	left: 10%;
  width: 1100px;
  max-width: 1100px;
	color: #00B0FF;
	font-weight: bold;
	font-size: 60px;
	text-align: left;
	overflow: hidden;
}

.services-header-title:before {
  background-color: #00B0FF;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 300px;
  max-width: 300px;
  display: none;
}

.services-header-title:before {
	right: 0.5em;
	margin-left: 0%;
}

main {
	display: flex;
	height: 100vh;
}

.header {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 3fr;
	min-width: 100%;
	margin: auto;
}

.header__title {
	font-family: 'Helvetica', sans-serif;
	font-size: 5rem;
	font-weight: 800;
	width: max-content;
	margin-right: 2em;
}

.header__title:after {
	content: "\2026";
  width: 0px;
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	animation: ellipsis steps(4, end) 1600ms infinite;
}

.header__subtitle {
	grid-column: 4;
	grid-row: 2;
	display: flex;
	align-items: center;
	margin-left: auto;
	margin-right: 2rem;
}

.header__subtitle > * + * {
	margin-left: .2rem;
}

.header__subtitle p {
	font-size: .8rem;
}

.header__logo {
	width: 15px;
	height: 15px;
}

.clip-me {
	max-width: 200px;
	clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
}

.opacity--level-1 {
  background-color: #00B0FF;
	opacity: 0.7;
}

.opacity--level-2 {
  background-color: #00B0FF;
	opacity: 0.3;
}

.header img:nth-child(1) {
	grid-row: 1 / span 2;
	grid-column: 1;
}
.header img:nth-child(2) {
	grid-row: 1 / span 2;
	grid-column: 2;
}
.header img:nth-child(3) {
	grid-row: 1 / span 2;
	grid-column: 3;
}
.header .header__title {
	grid-row: 1 / span 2;
	grid-column: 3 / span 4;
	z-index: 10;
	display: flex;
	margin: auto;
	margin-top: 6.25rem;
	margin-left: 15%;
}

@keyframes ellipsis {
	to {
		width: 1.25em;
	}
}

#services-wrapper {
    width: 1100px;
    margin: 0 auto;
  }
  
  @media screen and (max-width:940px) {
    #services-wrapper {
      width: 380px;
      margin: 0 auto;
      margin-top: -20px;
    }
    
    #services-wrapper .services-card {
      padding-bottom: 30px;
      padding-top: 30px;
      width: 300px;
    }
  
    #home-serviceimgid {
      width: 100%;
      height: 100%;
    }

    #clients-section #services-wrapper .row {
      position: relative;
    }

    .services-top-wave {
      position: relative;
      top: -16em;
      margin: 0 auto;
      margin-bottom: -15em;
      max-width: 1800px;
      width: 100%;
      overflow: hidden;
    }

    .services-header-title {
      z-index: 999;
      width: 100%;
      left: 10%;
      font-size: 10vw;
      top: 60%;
    }

    #services-img {
      position: relative;
      top: 20px;
      width: 100%;
      height: 500px;
      object-fit: cover;
      filter: brightness(30%);
    }
  }

#home-serviceimgid {
  width: 150px;
  height: 150px;
}

#home-img-body {
  margin: auto;
  width: 50%;
}

.card-title, 
.card-text {
  text-align: center;
}

#home-services-card-id .card-text {
  color:#6c757d;
}

#home-img-body img {
  border: 6px solid transparent;
  border-radius: 50%;
  padding: 10px;
  background-color: #def4ff;
}
 
#serviceimgid {
  width: 200px;
  height: 200px;
}

#img-body img {
  border: 6px solid transparent;
  border-radius: 50%;
  padding: 10px;
  margin: auto; 
  background-color: white;
}

#services-first-txt {
  font-size: 20px;
  width: 50%;
}

#services-first-txt::after {
  display: block;
  content: '';
  position: relative;
  height: 4em;
  width: 1%;
  border-radius: 20px;  
  background-color: #2c3892;
  margin: 0 auto;
  display: none;
}

#services-first-txt p {
  color: #6c757d;
}
  
#services-first-txt span {
  color: #00B0FF;
}

#services-first-txt-line  {
  /* border-bottom: 2px solid #00B0FF; */
  width: 30%;
}

#services-first-txt-blue {
  color: #00B0FF;
  font-style: italic;
}

#services-header-txt {
  text-align: left;
  font-weight: bold;
  font-size: 2.5em;
  width: 60%;
}

.circle-title {
  text-align: center;
  overflow: hidden;
}

.circle-title:before,
.circle-title:after {
  background-color: #00B0FF;
  content: "";
  display: inline-block;
  height: 5px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.circle-title:before {
  margin-left: -100%;
  margin-bottom: -600px;
}
.circle-title:after {
  margin-right: -100%;
  margin-top: -600px;
}


@media only screen and (max-width:940px) {
  #services-first-txt {
    font-size: 20px;
    padding-right: 40px;
    padding-left: 40px;  
    position: relative;
    border-radius: 0 0 0 0;
    padding-bottom: 20px;
    margin-left: 0px;
  }

  #services-first-txt-line  {
    width: 40%;
  }

}

.home-services-card .card-title {
  /* text-transform: uppercase; */
  font-weight: bold;
  font-size: 2em;
}

.home-services-card .card-text {
  font-size: 20px;
}

.home-services-custom-txt {
  font-size: 45px;
  text-align: center;
  border: 10px solid #def4ff;
  box-shadow: 0.5rem 0.5rem #00B0FF;
  transition: 0.7s;
  
}
.home-services-custom-txt:hover {
  background-color: #def4ff;
}

#home-services-num-body {
  text-align: right;
}

.home-services-nums {
  font-size: 30px;
  color: #00B0FF;
  font-weight: 900;
}

.home-services-nums-txt {
  font-size: 20px;
}

@media only screen and (min-width:940px) {

  #home-services-card-id {
    padding-top: 40px;
    margin-bottom: 40px;
    height: 490px;
    border-radius: 20px;
    border: transparent;
  }
}

@media only screen and (max-width:940px) { 

  #home-services-card-id {
    border: transparent;
    border-radius: 0px;
  }
}

#home-services-card-id:hover {
  transition: 0.5s;
  transform: scale(1.05);
}

#home-services-card-id:hover #home-img-body img {
  border: 5px solid transparent;
  transition: 0.6s;
}


@media only screen and (max-width:940px) {
  #home-services-num-body {
    text-align: left;
  }

  #home-services-card-id {
    margin-bottom: 30px;
    margin-top: -20px;
  }

  #home-services-card-id:hover {
    transition: 0.5s;
    transform: scale(1);
  } 

  #mobile-service-cards {
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
}

.services-card .card-title {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 30px;
}

.services-card .card-text {
  font-size: 20px;
  z-index: 999;
}

.services-custom-txt {
  font-size: 45px;
  text-align: center;
  transition: 0.7s;
  padding: 20px;
  width: 80%;
  margin: 0 auto;
  font-weight: bold;
  font-size: 2.5em;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

#why-1, #why-2, #why-3 {
  padding: 30px;
  text-align: center;
}

#why-2, #why-3 {
  border-left: 3px solid #00B0FF;
}

#services-page-header-txt {
  font-size: 3em;
  text-align: center;
  padding-bottom: 100px;
}

#services-page-header-txt span {
  color: #00B0FF;
}

.services-page-rows .row {
  padding-top: 40px;
  padding-bottom: 12em;
}

@media screen and (max-width: 900px) {

  #why-2, #why-3 {
    border-left: none;
  }

  .services-custom-txt {
    font-size: 2.4em;
    text-align: center;
    transition: 0.7s;
    padding: 8px;
    color: white;
    font-weight: bold;
    font-size: 2.5em;
    position: relative;
  }

  .services-page-rows .row {
    padding-bottom: 6em;
  }

}


.tests-question-txt {
  font-size: 2.5em;
  text-align: left;
  transition: 0.7s;
  font-weight: bold;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

#services-num-body {
  text-align: center;
  margin: 0 auto;
}

#services-card-id {
  background-color: white;
}

.services-nums {
  font-size: 30px;
  color: #00B0FF;
  font-weight: 900;
  position: relative;
  z-index: 9;
}

.services-nums::after {
  display: block;
  content: '';
  position: relative;
  height: 2em;
  width: 2em;
  border-radius: 50%;
  margin: 0 auto;
  background-color: #def4ff;
  z-index: -1;
  top: -1.7em;
  left: -0.15em;
}

.services-nums-txt {
  font-size: 20px;
  color: #6c757d;
}

.tests-txt {
  color: black;
  box-shadow: 10px 10px 30px 0 rgba(68, 88, 144, 0.1);
}

#whatclients h3 {
  text-align: center;
}

#whatclients-txt {
  font-size: 20px;
  color: #fff;
  width: 60%;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 40px;
}

#clients-section {
  padding-top: 0px;
  background: linear-gradient(to top, #161c4c 0%, #2e9aed 100%);
  max-width: 1800px;
  margin: 0 auto;
}

#home-bottom-wave {
  background-color: #f7f7f7;
}

.home-bottom-wave {
  background-color: transparent;
}

#home-wave {
  position: relative;
  margin-bottom: -12em;
}

@media screen and (max-width: 900px) {

  #home-wave {
    position: relative;
    top: 10px;
    margin-bottom: 2em;
  }

  #home-services-header-text {
    margin-bottom: 40px;
  }

  #home-portfolio-header-text {
    margin-bottom: -40px;
  }

  #logo_bg_body img {
    height: 400px;
  }

  #why-1, #why-2, #why-3 {
    text-align: left;
    margin: 0 auto;
    position: relative;
    top: -80px;
  }

  .services-nums::after { 
    display: none;
  }

  #portfolio-section {
   position: relative;
   top: -60px;
   margin-bottom: -200px;
  }

  .tests-question-txt {
    text-align: center;
    position: relative;
    width: 80%;
    margin: 0 auto;
  }

  #whatclients-txt {
    text-align: center;
    position: relative;
    padding: 0;
    width: 80%;
    margin: 0 auto;
  }

  #second-row-tests {
    position: relative;
    top: -160px;
  }

  #home-bottom-wave {
    padding-top: 13em;
  }

  #services-section {
    width: 380px;
    margin: 0 auto;
    margin-bottom: -16em;
  }

  .services-nums-txt {
    font-size: 20px;
    color: white;
  }

  #services-first-txt {
    text-align: center;
    font-size: 20px;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    margin: 0 auto;
  }

}

@media screen and (max-width:1200px) {
  #bottom-wave {
    margin-top: -250px;
  }
}

@media screen and (max-width:1000px) {
  #bottom-wave {
    margin-top: -200px;
  }
}

@media screen and (max-width:500px) {
  #bottom-wave {
    margin-top: -160px;
  }
}

@media screen and (min-width:1500px) {
  #bottom-wave {
    margin-top: -350px;
  }

  #about-page #bottom-wave {
    margin-top: 100px;
  }
}

@media screen and (min-width:1700px) {
  #bottom-wave {
    margin-top: -400px;
  }


  #about-page #bottom-wave {
    margin-top: -300px;
  }
}

#read-more-btn a{
  position:relative;
  text-decoration:none;
  display:inline-block;
  text-decoration: none;
  color: #00B0FF;
}

#read-more-btn a:after {
  display:block;
  content: '';
  border-bottom: solid 3px #00B0FF;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  transform-origin:100% 50%;
}

#read-more-btn a:hover:after { 
  transform: scaleX(1);
  transform-origin:0 50%;
}

#rescard:hover {
  transition: 0.3s;
  background-color: #def4ff;
}

@media only screen and (max-width:940px) {
  #services-num-body {
    text-align: left;
  }
}

#services-card-body {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 10px 10px 30px 0 rgba(68, 88, 144, 0.15);
}

#servies-card-title {
  color: #00B0FF;
  z-index: 999;
}

.our-services {
  overflow: hidden;
  position: relative;
  padding: 0 0 70px;
  margin-bottom: -80px;
}

time {
  display: block;
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 8px;
  color: #00B0FF;
  text-transform: uppercase;
}

time span {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 8px;
  border-radius: 50%;
  padding: 8px;
  background: #fff;
  border: 2px solid #00B0FF;
  color: #00B0FF;
  padding-left: 17px;
  padding-right: 17px;
}

.desktop-service {
  display: block;
}

.mobile-service {
  display: none;
}

#faq-section {
  background-color: #def4ff;
  margin-bottom: -60px;
}

.services-wave {
  background-color: #def4ff;
}

#faq-body {
  width: 1100px;
  margin:  0 auto;
}

.service-time-text {
  line-height: 1.7em;
  font-size: 1.3em;
  color: #6c757d;
}

.service-time-text-list {
  font-size: 1.1em;
}

.service-time-text-list-header {
  font-weight: 900;
} 

.service-time-text span a {
  color: #00B0FF;
}

#services-header-process {
  font-size: 20px;
}

.faq-drawer {
  margin-bottom: 30px;
}

.faq-drawer__content span a {
  color: #00B0FF;
}

.faq-drawer__content-wrapper {
  padding-top: 20px;
  font-size: 1.1em;
  line-height: 1.4em;
  max-height: 0px;
  overflow: hidden;
  color: black;
  padding-left: 10px;
  transition: 0.25s ease-in-out;
}

.faq-drawer__title {
  background-color: #def4ff;
  border-top: 2px solid #00B0FF;
  margin-top: 20px;
  cursor: pointer;
  display: block;
  font-size: 1.25em;
  font-weight: 700;
  padding: 30px 0 0 0;
  position: relative;
  margin-bottom: 0;
  transition: all 0.55s ease-out;
}

.faq-drawer__title::after {
  content: " ";
  position: absolute;
  display: block;
  background-color: black;
  height: 0.1em;
  width: 20px;
  margin-top: -5px;
  top: 50%;
  right: 5px;
  z-index: 9;
  transition: 0.5s ease-in-out;

}
.faq-drawer__title::before {
  content: " ";
  position: absolute;
  display: block;
  background-color: black;
  width: 0.1em;
  height: 18px;
  right: 13px;
  top: 17px;
  bottom: 5px;
  z-index: 9;
  transition: 0.5s ease-in-out;
}

.faq-drawer__trigger:checked
  + .faq-drawer__title
  + .faq-drawer__content-wrapper {
  max-height: 100%;
}

#last-faq
.faq-drawer__trigger
+ .faq-drawer__title
+ .faq-drawer__content-wrapper {
  max-height: 100%;
}

#last-faq
.faq-drawer__trigger:checked
+ .faq-drawer__title
+ .faq-drawer__content-wrapper {
  max-height: 0%;
  display: none;
}


.faq-drawer__trigger:checked + .faq-drawer__title::after {
  border-bottom: 0;
  color: #00B0FF;
  transform: rotate(-180deg);
  transition: 0.5s ease-in-out;
}

.faq-drawer__trigger:checked + .faq-drawer__title::before {
  border-bottom: 0;
  color: #00B0FF;
  transform: rotate(90deg);
  transition: 0.5s ease-in-out;
}


input[type="checkbox"] {
  display: none;
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 80px;
  }
  #faq-body {
    margin-top: -100px;
    margin-bottom: 100px;
    width: 100%;
  }

  .services-page-rows .row {
    margin: 0 auto;
    width: 380px;
    /* border: 2px solid red; */
  }

  .desktop-service {
    display: none;
  }

  .mobile-service {
    display: block;
  }

  .card-img {
    padding-bottom: 100px;
  }

  .faq-drawer__title {
    font-size: 1em;
  }

  .faq-drawer__title::before {
    content: " ";
    position: absolute;
    display: block;
    background-color: black;
    width: 0.1em;
    height: 18px;
    right: 13px;
    top: 14px;
    bottom: 5px;
    z-index: 9;
    transition: 0.5s ease-in-out;
  }

  .blue_arrow_left, 
  .blue_arrow {
    display: none;
  }

}

@media screen and (min-width:1500px) {
  #faq-section .row {
    margin-bottom: 300px;
    width: 100%; 
    
  }
}

.last-service-row .home-btn-body {
  padding-top: 8em;
  left: 16em;
}

.last-service-row .home-btn-body .home-btn {
  color: #000;
}

.last-service-row .home-btn-body a:hover .home-btn {
  color: #fff;
}

.blue_arrow {
  transform: scale(0.5);
  width: 12%;
  position: relative;
  left: 4em;
  transform: rotate(30deg);
  margin-bottom: -20em;
  opacity: 0.15;
}

.blue_arrow_left {
  transform: scale(0.5);
  width: 12%;
  position: relative;
  left: 4em;
  transform: rotate(30deg);
  margin-bottom: -20em;
  opacity: 0.15;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1)
}

/* TUTORING */

.tutoring-page .services-wave {
  background-color: transparent;
}

.tutoring-page .service-time-text {
  font-size: 1.2em;
}

#tutoring-img-body img {
  filter: grayscale(100%);
  transition: 0.2s ease-in;
}

#tutoring-img-body img:hover {
  filter: grayscale(0%);
}

#tutoring-part .services-btn,
#handup-section .services-btn {
  float: left;
}

#online-tut-section {
  padding-bottom: 5em;
}

#online-tut-section .service-time-text {
  width: 85%;
}

#handup-section {
  padding-top: 6em;
  padding-bottom: 2em;
  background-color: #def4ff;
}

#handup-section img {
  filter: grayscale(0%);
}

#tutoring-bottom {
  padding-top: 0em;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1200px;
}

#tutoring-bottom h2 {
  padding-top: 2em;
  padding-bottom: 2em;
  text-transform: uppercase;
  font-size: 2em;
  font-weight: 700;
  text-align: left;
  color: var(--primary);
  display: none;
}

#tutoring-bottom .row .col-lg-4 img {
  width: 100px;
  margin: 0 auto;
  opacity: 0.2;
  padding-bottom: 1.4em;
}

#tutoring-bottom .row .col-lg-4 {
  margin: 0 auto;
  font-size: 1.1em;
  font-weight: 800;
}

#tutoring-bottom p {
  width: 170px;
  margin: 0 auto;
  color: var(--primary);
}

@media only screen and (max-width: 900px) {
  #tutoring-img-body {
    padding-top: 6em;
  }

  #handup-section {
    padding-top: 0em;
  }

  #tutoring-bottom {
    width: 100%;
    padding-top: 0em;
  }

  #tutoring-bottom .row .col-lg-4 {
    padding-bottom: 4em;
  }

  #online-tut-section {
    padding-bottom: 3em;
  }

  #services-header-txt {
    text-align: center;
    font-weight: bold;
    font-size: 2.2em;
    width: 90%;
    margin: 0 auto;
  }

}

#home-wave {
  position: relative;
  top: 7em;
  margin-top: -10em;
  z-index: 999;
  transform: rotate(180deg);
}

#home-bottom-wave {
  position: relative;
  z-index: 999;
  top: -18em;
  margin-bottom: -19em;
  background-color: transparent;
}

.mobile-btn {
  display: none;
}

.mobile-btn-tutoring {
  padding-top: 5em;
}

.mobile-btn-tutoring .home-btn-body .home-btn {
  color: #000;
}

.mobile-btn-tutoring .home-btn-body a:hover .home-btn {
  color: #fff;
}

#scroll {
   display: flex;
   animation: scroll 15s linear infinite;
}

#scrollbody {
  overflow:hidden;
}

.item {
  flex: 0 0 auto;
  width: 300px;
  margin-right: 20px;
}

.item img {
  width: 100%;
}

#unawebwed-section {
  padding-top: 8em;
}

#unawebwed-section p {
  width: 80%;
}

#unawebwed-vids .row {
  padding-top: 2em;
}

#unawebwed-vids .row iframe {
  width: 100%;
  height: 200px;
}

#unawebwed-section span {
  font-weight: 700;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (max-width: 900px) {
  #home-bottom-wave {
    z-index: 99;
    padding-top: 0em;
    margin-top: 13em;
  }

  #home-bg-section .home-bg-content-text {
    position: relative;
    z-index: 999;
  }

  .mobile-btn {
    display: block;
  }

  .mobile-btn .main-btn-blue {
    position: relative;
    left: 5em;
  }

  .mobile-btn .home-btn-body {
    left: -2em;
    padding-top: 12em;
    margin-bottom: -12em;
  }

  .mobile-btn-tutoring {
    padding-top: 6em;
    margin-bottom: -12em;
    left: -4em;
    position: relative;
  }

  #tutoring-img-body {
    margin-bottom: -4em;
  }

  #scroll {
    animation: scroll 5s linear infinite;
  }

  .item {
    width: 200px;
  }

  #unawebwed-section {
    padding-top: 0em;
  }

  #unawebwed-section time {
    padding-left: 0.7em;
  }
  
  #unawebwed-section p {
    padding-left: 1.1em;
    padding-right: 1em;
    width: 100%;
  }
  

}