#portfolio-wrapper {
    width: 1100px;
    margin: 0 auto;
}

@media screen and (max-width:940px) {
    #portfolio-wrapper {
        width: 400px;
        margin: 0 auto;
    }
}

#portfolio-page-wrapper {
    width: 1100px;
    margin: 0 auto;
}

@media screen and (max-width:940px) {
    #portfolio-page-wrapper {
        width: 400px;
        margin: 0 auto;
        margin-top: -150px;
    }

    .port-page-card {
        margin-top: -200px;
        left: 0;
        margin-bottom: 200px;
    }

    #site-name {
        border: 2px solid transparent;
        font-size: 1.2em;
        border-bottom: 2px solid #00B0FF;
    }
}

@media screen and (min-width:1500px) {
    #portfolio-page-wrapper {
        margin-bottom: 200px;
    }

    #lets-get-started-service {
        position: relative;
        z-index: 99;
    }
}

#home-portfolio-text {
    width: 100%;
}

#home-portfolio-header-text {
    font-size: 1.2em;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #2c3892;
}

.portfolio-txt {
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2em;
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

.portfolio-header {
    padding-top: 2em;
}

.portfolio-header h1 {
    padding-left: 2em;
}

.portfolio-header .portfolio-sub-header {
    padding-left: 5em;
}

.profile_border {
    height: 200px;
    width: 200px;
    background: transparent;
    border-radius: 200px;
    transition: border 1s;
}

.home-pcard {
    height: 320px;
    width: 100%;
    background: #808080;
    overflow: hidden;
    background: #000;
    border: 2px solid #00B0FF;
    margin: auto;
}

#home-pcard1 {
    background-position: center;
    object-fit: contain;
    background-size: cover;
    border-radius: 0 0 0 0;
    background-blend-mode: unset;
    transition: 0.6s;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 0.3);
}

#home-pcard1:hover {
    opacity: 0;
}

#home-pcard2 {
    background-position: center;
    object-fit: fill;
    background-size: cover;
    border-radius: 0 0 0 0;
    background-blend-mode: unset;
    transition: 0.6s;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 0.3);
}

#home-pcard2:hover {
    opacity: 0;
}

#home-pcard3 {
    background-position: center;
    object-fit: fill;
    background-size: cover;
    border-radius: 0 0 0 0;
    background-blend-mode: unset;
    transition: 0.6s;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 0.3);
}

#home-pcard3:hover {
    opacity: 0;
}

.home-pcard h2 {
    font-size: 22px;
    color: white;
    opacity: 0;
    width: 100%;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.home-pcard h3 {
    font-size: 18px;
    color: white;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 20px;
    opacity: 0;
    width: 100%;
}

.home-pcard .hover_name {
    color: #00B0FF;
    text-transform: uppercase;
}

#home-pcard1:hover h2,
#home-pcard2:hover h2,
#home-pcard3:hover h2 {
    opacity: 1;
}

#home-pcard1:hover h3,
#home-pcard2:hover h3,
#home-pcard3:hover h3 {
    opacity: 1;
}

.home-portfolio-btn {
    display: block;
    font-size: 15px;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #2c3892;
    background-color: white;
    color: black;
    width: 200px;
    margin-left: 80px;
    position: relative;
    top: 80px;
}

.home-portfolio-btn a {
    text-decoration: none;
    color: #2c3892;
}

.eds-logos-card a {
    text-decoration: none;
}

.portfolio-full-screen-site {
    padding: 20px;
}



.portfolio-img {
    height: 100%;
    width: 100%;
    transition: 0.3s;
}

.portfolio-img:hover {
    transform: scale(1.05);
    transition: 0.3s;
    cursor: pointer;
}



#btn-body {
    width: 40%;
    margin: auto;
}

#portfolio-contact-btn-body {
    width: 16%;
    margin: auto;
}

.portfolio-site-btn {
    display: block;
    font-size: 18px;
    border: 0;
    text-decoration: none;
    padding-left: 20px;
    padding-right: 20px;
    padding: 15px;
    background-color: white;
    transition: 0.7s cubic-bezier(0.39, 0.5, 0.15, 1.36);
    margin: auto;
    font-weight: bold;
    color: #fff;
    color: #2c3892;
    border-left: 4px solid #2c3892;
    /* box-shadow: #2c3892 0 0 0 4px inset; */
    background-color: white;
    /* box-shadow: #2c3892 0 0 0 40px inset; */
    /* border-radius: 50px; */
    padding: 20px 30px;
}

.portfolio-site-btn:hover {
    color: #2c389275;
    /* box-shadow: #2c3892 0 0 0 4px inset; */
    background-color: white;
}

.portfolio-body {
    max-width: 1400px;
    margin: 0 auto;
}

.portfolio-examples {
    padding-bottom: 100px;
    padding: 1em;
    max-width: 1400px;
    margin: 0 auto;
}

#lets-get-started-service {
    padding-top: 5em;
    text-align: center;
}

#lets-get-started-service .home-btn {
    margin-top: 1em;
}

#lets-get-started-service .home-btn a {
    color: #00B0FF;
}

#lets-get-started-service .home-btn:hover a {
    color: #fff;
}

@media screen and (max-width:940px) {

    .client-txt {
        border-right: none;
    }

   .port-body {
        padding-bottom: 20px;
   }
   
   #pcard1 {
        height: 40%;
        width: 40%;
        margin-bottom: -200px;
        margin-left: -20px;
   }   

   #btn-body,
   #portfolio-contact-btn-body {
        width: 60%;
        margin: auto;
   }

   .portfolio-examples {
        padding-bottom: 0px;
        margin-bottom: -200px;
    }

    .portfolio-full-screen-site {
        position: relative;
        padding: 0px;
        margin-bottom: -200px;
    }

    .second-port-row {
        position: relative;
        top: -200px;
    }

}

.pcard-txt {
    background-color: white;
    font-size: 20px;
    padding: 20px;
    border-radius: 20px;
    /* box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.1); */
}

.pcard-txt p {
    font-size: 18px;
    color: #6c757d;
}

.pcard-txt h2 {
    color: #00B0FF;
    font-size: 1.4em;
    font-weight: bold;
}

.home-our-work {
    overflow: hidden;
    position: relative;
}

.home-our-work.rotated-bg:before {
    content: '';
    position: absolute;
    width: 600px;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 600px;
    left: 50%;
    top: 50%;
    z-index: -1;
    border-radius: 300px;
    background-color: #def4ff;
}

.our-work {
    overflow: hidden;
    position: relative;
    padding: 0 0 70px;
    margin-bottom: -80px;
}

.our-work.rotated-bg:before {
    content: '';
    position: absolute;
    width: 400px;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 800px;
    left: -10%;
    top: 18%;
    z-index: -1;
    border-radius: 300px;
    background-color: #def4ff;
}

.our-work.rotated-bg2:before {
    content: '';
    position: absolute;
    width: 800px;
    transform: translate(-50%, -50%) rotate(45deg);
    height: 600px;
    left: 110%;
    top: 100%;
    z-index: -1;
    border-radius: 300px;
    background-color: #def4ff;
}

.img__wrap {
    position: relative;
    height: 300px;
    width: 350px;
}

.img__description_layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    visibility: visible;
    opacity: 1;
    align-items: center;
    justify-content: center;
    transition: opacity .2s, visibility .2s;
    background-size: 100% 200%;
    transition: 0.5s;
    /* background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 90%);  */
}
  
.img__wrap:hover .img__description_layer {
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.9) 00%); 
    transition: 0.5s;
}

.img__wrap:hover .home-portfolio-btn {
    position: relative;
    top: 0px;
    transition: 0.5s;
}

.img__title {
    font-size: 22px;
    color: transparent;
    margin-top: 90px;
    text-align: center;
}
  
.img__description {
    padding-left: 40px;
    padding-right: 40px;
    transition: .2s;
    transform: translateY(1em);
}
  
.img__wrap:hover .img__description {
    transform: translateY(0);
}

.img__img {
    height: 160px;
    width: 340px;
    object-fit: cover;
}

.pcard-txt #btn-body {
    float: left;
}

.mobile-work {
    display: none;
}


.switch-body {
    padding-top: 5em;
    padding-bottom: 5em;
    border-radius: 50px;
}

.switch-button {
	width: 400px;
	height: 60px;
	text-align: center;
	position: absolute;
	transform: translate3D(-50%, -50%, 0);
	will-change: transform;
	z-index: 197;
	cursor: pointer;
	transition: .3s ease all;
	border: 2px solid var(--primary);
    border-radius: 50px;
}

.switch-button-case {
    display: inline-block;
    background: none;
    width: 49%;
    height: 100%;
    color: #000;
    position: relative;
    border: none;
    transition: .3s ease all;
    text-transform: uppercase;
    letter-spacing: 5px;
    padding-bottom: 1px;
    border-radius: 50px;
}

.switch-button-case:hover{
    cursor: pointer;
}

.switch-button-case:focus {
     outline: none;
}
.active {
    color: #fff;
    background-color: var(--primary);
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
    transition: .3s ease-out all;
    border-radius: 50px;
}

.active-case {
    color: #fff;
}

.portfolio-sub-header {
    font-size: 1em;
    color: var(--primary);
    text-align: center;
}

#portfolio-page #about-page-btn-section h1 {
    width: 35%;
    margin: 0 auto;
}

#portfolio-page #about-page-btn-section p {
    width: 50%;
}

.portfolio-popup {
    max-width: 1100px;
    margin: 0 auto;
    padding: 2em;
    padding-top: 5em;
}

.portfolio-popup-top-img {
    width: 1000px;
    height: 600px;
    margin: 0 auto;
    padding-bottom: 2em;
    border-bottom: 2px solid #00B0FF;
}

.portfolio-popup-top-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.portfolio-popup .row {
    padding-bottom: 2em;
}

.portfolio-menu {
    padding-top: 2em;
}

.portfolio-menu h2 { 
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: 700;
}

.portfolio-content {
    padding-top: 2em;
}

.portfolio-menu p {
    color: #6c757d;
    font-size: 1.1em;
    margin-bottom: 0.5em;
}

.portfolio-menu a {
    color: #00B0FF;
    font-size: 1.2em;
}

.portfolio-menu a:hover {
    color: #0381bb;
}

.portfolio-content p {
    font-size: 1.3em;
    color: #6c757d;
}

.portfolio-link {
    padding-bottom: 3em;
}

.portfolio-img-content {
    display: none;
}

.active-content {
    display: block;
}

.portfolio-pic {
    width: 100%;
    height: 100%;
}

.portfolio-pic-container {
    position: relative;
    overflow: hidden;
}

.portfolio-pic {
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
}

.portfolio-pic-container:hover .portfolio-pic {
    filter: blur(3px);
}

.portfolio-pic-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 0.35s ease;
    color: black;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
}

.portfolio-pic-container:hover .portfolio-pic-overlay {
    opacity: 1;
}

.portfolio-pic-overlay h2 {
    margin: 0;
    font-size: 26px;
    font-weight: 800;
    color: var(--primary);
}

.portfolio-pic-overlay p {
    margin: 5px 0 0;
    font-size: 18px;
    font-family: "Work Sans";
}


@media screen and (min-width:1500px) {

  #portfolio-page #bottom-wave {
    margin-top: 0px;
  }
}

@media screen and (min-width:1700px) {

  #portfolio-page #bottom-wave {
    margin-top: -300px;
  }
}

@media screen and (max-width:940px) {
    .pcard-txt {
        margin-bottom: -200px;
        position: relative;
        top: -150px;
    }

    .port-card {
        position: relative;
        left: 25px;
    }

    #lets-get-started-service {
        /* margin-top: 200px; */
        margin-bottom: 100px;
    }
    
    .pcard {
        height: 400px;
        width: 900px;
        margin: auto;
    }

    .pcard-txt #btn-body {
        float: left;
        position: relative;
        right: 8%;
    }

    .home-our-work {
        padding: 0 0 70px;
    }

    #home-portfolio-text {
        padding: 0em;
        padding-left: 0.1em;
        margin-bottom: -1em;
    }

    #left-portfolio {
        padding-top: -2em;
        top: -10%;
        margin-bottom: -10em;
    }

    .cny_drones {
        margin: 0 auto;
        position: relative;
        left: -3em;
        padding-bottom: 1em;
    }

    .mobile-work {
        display: block;
    }

    #work-large {
        display: none;
    }

    .portfolio-header .portfolio-sub-header {
        padding-left: 2.5em;
    }
    .portfolio-header .portfolio-txt {
        padding-left: 1em;
    }

    .switch-button {
        width: 360px;
        left: 12em;
    }

    .portfolio-img-body {
        padding-bottom: 3em;
    }

    .portfolio-popup-top-img {
        width: 100%;
        height: 100%;
        margin-top: 6em;
    }

    #portfolio-page #about-page-btn-section {
        padding-bottom: 15em;
    }

    #portfolio-page #about-page-btn-section h1 {
        width: 80%;
    }

    #portfolio-page #about-page-btn-section p {
        width: 300px;
    }

    .portfolio-main-btn {
        width: 250px;
        position: relative;
        left: 1em;
        margin: 0 auto;
    }

}