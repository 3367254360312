.blog-page a {
    color: var(--primary);
}

.blog-page a:hover {
    color: var(--primary);
    opacity: 0.8;
}

.white-box {
    padding: 20px;
    border-radius: 5px;
    margin-bottom:  30px;
}

.sidebar, .mobile-sidebar {
    width: 350px;
    border: 2px solid var(--primary);
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.1);
}

.sidebar a,
.mobile-sidebar a {
    color: black;
}

.sidebar a:hover,
.mobile-sidebar a:hover {
    color: var(--primary);
}

.sidebar h2,
.mobile-sidebar h2 {
    font-weight: 700;
}

.sticky-panel {
    position: fixed;
    top: 150px;
}

.move-up {
    transform: translateY(-400px);
}

#blog .content h1 {
    font-weight: 900;
}

#blog .content h2 {
    padding-top: 2em;
    font-weight: 700;
    padding-bottom: 0.5em;
    color: var(--primary);
    /* background:linear-gradient(180deg, transparent 60%, #1eb8ff81 60%); */
}

#blog .content h3 {
    font-size: 1.3em;
    font-weight: 700;
}

#blog .content p,
.sidebar p,
.mobile-sidebar p {
    color: #4e4b66;
    font-family: "Work Sans";
    font-size: 1.1em;
}

#blog .content img {
    padding-top: 2em;
    padding-bottom: 2em;
    width: 100%;
}

.blog-page .bottom-wave {
    position: relative;
    z-index: 3;
}

.blog-page .mobile-menu {
    display: none;
}

@media screen and (max-width: 700px) {

    .blog-page .services-header-title {
        font-size: 2em;
    }

    .blog-page .mobile-menu {
        display: block;
    }

    .blog-page .desktop-menu {
        display: none;
    }

}