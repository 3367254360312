#hero-page,
#contact-page,
footer,
#portfolio-page,
#services-page {
  overflow-x: hidden;
}

#about-wrapper {
  width: 1100px;
  margin: 0 auto;
}

#process-wrapper {
  width: 1100px;
  margin: 0 auto;
}

#about-section {
  padding-top: 100px;
  padding-bottom: 150px;
  background-color: #2e9aed;
  max-width: 1800px;
  margin: 0 auto;
}

#why-custom-better-section .home-blog-btn {
  padding-top: 4em;
  padding-bottom: 4em;
}

#why-custom-better-section .home-blog-btn .about-btn {
  margin: 0 auto;
  float: none;
}

#why-custom-better-section {
  margin-top: 50px;
  display: none;
}

@media screen and (max-width:940px) {
  #about-wrapper {
    width: 380px;
    margin: 0 auto;
  }

  #process-wrapper {
    width: 380px;
    margin: 0 auto;
  }

  #about-page #bottom-wave {
    position: relative;
    top: 10px;
  }

  #about-page-body {
    margin-top: -100px;
    margin-left: 0 auto;
    margin-right: 0 auto;
    padding: 10px;
  }

  #why-custom-better-section {
    background-color: #2d648f;
    margin-top: -200px;
    margin-bottom: -250px;
    padding-top: 0px;
  }

  #why-custom-better-section .home-blog-btn {
    padding-top: 0em;
    padding-bottom: 12em;
  }
  
  #why-custom-better-section .home-blog-btn .about-btn {
    margin: 0 auto;
    float: left;
    background-color: transparent;
  }
}

/* @media screen and (min-width:1700px) {
  #about-wrapper {
    margin-bottom: 200px;
  }
}

@media screen and (min-width:1700px) {
  #about-wrapper {
    margin-bottom: 450px;
  }
} */

.about-name {
  text-transform: uppercase;
  color: #00B0FF;
}

.about-bio {
  font-weight: bold;
}
.about-top-txt {
  text-align: center;
  font-size: 22px;
}

.about-txt {
  text-align: center;
  font-size: 20px;
  padding-right: 200px;
  padding-left: 200px;
}
.about-txt p {
  color: #fff;
  text-align: left;
}

#home-approach-img img {
  width: 100%;
  height: 350px;
  position: relative;
  left: 100px;
}

#home-about-img img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
  position: relative;
  left: 50px;
}

@media screen and (max-width:940px) { 
  #home-about-img img {
    position: relative;
    top: -80px;
    margin-bottom: 20px;
    width: 80%;
    height: 90%;
    left: 30px;
  }

  #home-approach-img img {
    position: relative;
    top: 80px;
    margin-bottom: 20px;
    width: 80%;
    height: 90%;
    left: 30px;
  }

  #whatclients {
    position: relative;
    top: 40px;
  }

  #about-section {
    margin: auto;
  }
}

#about-home-our-story {
  text-align: left;
}

#about-bio-body p {
  text-align: center;
}

.about-txt-una {
  text-align: left;
  font-size: 20px;
  padding-right: 10px;
  padding-left: 10px;
}

.why-una {
  text-align: center;
  font-size: 3em;
  font-weight: bold;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

.why-una span {
  color: #00B0FF;
}

#about-first-txt-line {
  border-bottom: 2px solid #00B0FF;
  width: 40%;
}

#namebehindcompany {
  font-size: 30px;
}

.about-txt h3 {
  font-size: 2.3em;
  font-weight: bold;
  /* font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

.home-about-header-text {
  font-size: 0.875em;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
}


#unablue1, #unablue2 {
  color: #00B0FF;
}

.aboutimg .aboutpic {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.about-btn {
  display: block;
  font-size: 18px;
  border: 0;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  padding: 15px;
  background-color: white;
  transition: 0.7s cubic-bezier(0.39, 0.5, 0.15, 1.36);
  margin: auto;
  font-weight: bold;
  color: #fff;
  box-shadow: #2c3892 0 0 0 40px inset;
  border-radius: 50px;
  padding: 20px 30px;
  float: left;
}

.about-btn a {
  color: white;
}

.about-btn:hover {
  box-shadow: #00B0FF 0 0 0 4px inset;
  background-color: white;
}

.about-btn:hover a {
  color: #00B0FF;
}

a:link {
  text-decoration: none;
}

@media screen and (max-width:940px) {
  .about-txt {
    padding-right: 20px;
    padding-left: 20px;
  }

  #about-page-text {
    width: 110%;
    margin: 0 auto;
    left: -5%;
    position: relative;
  }

  #about-page-text p {
    text-align: left;
  }

  .about-txt-una {
    padding-right: 20px;
    padding-left: 20px;
  }

  .aboutpic {
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    /* display: none; */
  }

  .aboutimg .aboutpic {
    left: 0%;
  }

  .about-name {
    left: 0%;
  }

  .about-txt-hr:before,
  .about-txt-hr:after {
    background-color: #00B0FF;
    content: "";
    display: inline-block;
    height: 2px;
    position: relative;
    vertical-align: middle;
    width: 25%;
  }
}

#about-story h1,
#about-mission h1 {
  font-size: 2em;
  font-weight: bold;
  /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
}

#about-story p,
#about-mission p {
  color: #6c757d;
  /* font-size: 20px; */
}


.header-title:before,
.header-title:after {
  background-color: #00B0FF;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.header-title:before {
  right: 0.5em;
  margin-left: -50%;
}
.header-title:after {
  left: 0.5em;
  margin-right: -50%;
}

#about-river-pic-back {
  width: 490px;
  position: absolute;
  z-index: 1;
  height: 500px;
  background: #808080;
  overflow: hidden;
  margin: auto;
  background-color: #1a1a1a9f;
  background-blend-mode: darken;
  background-position: center;
  object-fit: fill;
  background-size: cover;
  filter:brightness(50%);
}

#about-river-pic-front {
  width: 420px;
  height: 420px;
  position: relative;
  top: 40px;
  left: 35px;
  z-index: 9;
}

#about-pic-txt-line {
  border-bottom: 3px solid #00B0FF;
  width: 40%;
  float: right;
}

@media screen and (max-width:940px) {
  #about-river-pic {
    width: 330px;
    height: 200px;
    border-bottom: 10px solid #00B0FF;
  }
}

#about-una-bio {
  background: rgb(240, 240, 240);
  height: 500px;
  padding: 20px;
}

.about_profile_border {
  height: 600px;
  width: 500px;
  background: rgb(240, 240, 240);
}

.about-una-bg .hover_name {
  color: #00B0FF;
  text-transform: uppercase;
}

#about-txt-pic {
  border: 3px solid #00B0FF;
}

@media screen and (max-width:940px) {
  #about-txt-pic {
    position: relative;
    width: 95%;
    left: 5px;
  }

}

.about-us {
  overflow: hidden;
  position: relative;
}

.about-us.rotated-bg:before {
  content: '';
  position: absolute;
  width: 1000px;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 500px;
  left: -10%;
  top: 50%;
  z-index: -1;
  border-radius: 300px;
  background-color: #def4ff;
}

.home-about:before {
  content: '';
  position: absolute;
  width: 300px;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 300px;
  left: 85%;
  top: 50%;
  z-index: -1;
  border-radius: 300px;
  background:  linear-gradient(to left, #2e9aed 0%, white 100%);
  opacity: 0.2;
}

#about-page .bubble {
  content: '';
  position: relative;
  width: 300px;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 300px;
  left: 115%;
  top: 20%;
  z-index: -1;
  border-radius: 300px;
  background:  linear-gradient(to left, #2e9aed 0%, white 100%);
  opacity: 0.2;
  float: left;
}

#services-page .bubble {
  content: '';
  position: absolute;
  width: 200px;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 200px;
  left: 15%;
  top: 8%;
  z-index: -1;
  border-radius: 300px;
  background:  linear-gradient(to left, #2e9aed 0%, white 100%);
  opacity: 0.2;
}

#about-page .small-bubble {
  content: '';
  position: absolute;
  width: 300px;
  transform: translate(-50%, -50%) rotate(45deg);
  height: 300px;
  left: 25%;
  top: 70%;
  z-index: -1;
  border-radius: 300px;
  background:  linear-gradient(to left, #2e9aed 0%, white 100%);
  opacity: 0.2;
}

#whyunaimg-body {
  overflow: hidden;
}

#whyunariverimg {
  border-radius: 50%;
  transition: 0.4s;
}

#whyunariverimg:hover {
  transition: 0.4s;
  transform: scale(1.9);
}

@media screen and (max-width:940px) {
  #why-una-body {
    margin-top: -200px;
    text-align: center;
  }
  
  #about-first-txt-line {
    display: none;
  } 

  #whyunaimg-body {
    position: relative;
    left: 20px;
  }

  #about-page .bubble {
    position: absolute;
    left: 125%;
    top: -20%;
  }

  #about-page .small-bubble {
    /* display: none; */
    left: 10%;
  }
}


.showcase {
  width: 100%;
  height: 500px;
  position: relative;
  color: white;
  text-align: center;
  clip-path: inset(0);
  margin-top: 2em;
}

.showcase img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index:99;
  position: fixed;
}

.showcase .overlay {
  width: 100%;
  height: 500px;
  background-color: rgba(0, 35, 82, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index:999
}

#whyunatext {
  padding: 20px;
  border: 2px solid #00B0FF;
  text-align: left;
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 1.2em;
}

#about-page-btn-section {
  content: "";
  position: relative;
  display: block;
  /* height: 300px; */
  padding-top: 100px;
  /* background-color: red; */
}

#about-page-btn-section p {
  width: 65%;
  text-align: center;
  font-size: 1.3em;
  color: #6c757d;
  margin: 0 auto;
}

#about-page-btn-section .home-btn {
  color: var(--primary);
}

#about-page-btn-section .home-btn:hover {
  color: #fff;
}

#home-numbers-section {
  background-color: white;
  color: black;
  text-align: center;
}

#home-numbers-section .card {
  border: none;
  color: #000;
  width: auto;
  position: relative;
  top: 0;
  transition: top ease 0.4s;
  height: 100%;
  width: 100%;
  z-index: 999;
}

#home-numbers-section .card h2 {
  padding-top: 0.8em;
  color: var(--primary);
  font-weight: 600;
  font-size: 6em;
}

.plus:after {
  content: "+";
  display: inline-block;
  height: 2px;
  width: 100px;
  position: relative;
  left: -0.2em;
}

#home-numbers-section .card p { 
  padding: 1.1em;
  font-size: 20px;
  color: #6c757d;
  width: 90%;
}

#about-page-body iframe {
  margin-top: 2em;
  width: 100%;
  height: 600px;
  border: 3px solid var(--primary);
}

#about-contact-btn-body {
  margin: 0 auto;
  width: 15%;
}

@media screen and (max-width:940px) {
  #whyunatext {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    border: none;
  }

  .showcase, 
  .showcase .overlay {
    height: 700px;
    width: 100%;
    margin-bottom: 100px;
  }

  .showcase img {
    width: 95%;
    left: 5px;
  }

  #about-page-btn-section {
    top: -80px;
  }

  #about-page-btn-section p {
    width: 100%;
    text-align: center;
    font-size: 1.3em;
    color: #6c757d;
    margin: 0 auto;
  }
  
  #about-contact-btn-body {
    margin: 0 auto;
    width: 80%;
  }

  #about-page {
    width: 100%;
    overflow: hidden;
  }

  #home-numbers-section .card h2 {
    padding-top: 0.2em;
    color: var(--primary);
    font-weight: 600;
    font-size: 4em;
  }

  #home-numbers-section .card p { 
    padding: 1.1em;
    padding-top: 0em;
    font-size: 20px;
    color: #6c757d;
    width: 100%;
  }

  #about-page-body iframe {
    margin-top: 2em;
    width: 100%;
    height: 350px;
    border: 3px solid var(--primary);
  }  

  .plus {
    position: relative;
    left: 0.5em;
  }

  .plus:after {
    left: -0.5em;
  }

}