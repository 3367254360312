nav {
  background-color: transparent;
} 

nav .container-fluid {
  max-width: 1800px;
  margin: 0 auto;
}

#imgid {
  transition: 0.3s;
}

.navbar a {
  color:white;
  font-weight: bold;
}

.navbar i {
  color:rgb(45, 228, 210);
}

.navbar .navbar-brand {
  padding: 0px;
}

#nav-link-dark {
  color: white;
}

.navbar-nav a { 
  font-size: 1.2em;
  margin-left: 20px;
  margin-right: 20px;
  float: right;
  color:white;
  text-align: center;
  font-weight: 900;
}

.navbar-nav a:after {
  transform: scaleX(0);
  display:block;
  content: '';
  border-bottom: solid 3px #00B0FF;  
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}

.navbar-nav a:hover:after{ 
  transform: scaleX(1);
}

.navbar-toggler {
  width: 30px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
}

.navbar-toggler:focus {
  border: 0 !important;
  box-shadow: none !important;
}
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: deepskyblue;
  opacity: 1;
  left: 0;
  transition: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  width: 70%;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* state when navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

@media screen and (max-width:940px) {

  #navid {
    background-color: white;
    background: linear-gradient(to top, #f3faff 0%, white 100%);
  }

  .navbar .navbar-brand {
    font-size: 40px !important;
  }  

  nav {
    background-color: rgb(240, 240, 240);
    max-width: 400px;
    margin: 0 auto;
  }

  .nav-item {
    color: black;
    width: 380px;
  }

  .nav-item a {
    color: #000;
  }

  .navbar-nav a { 
    color:black;
    flex-direction: column;
    text-align: center;
    transition: .3s;
    margin-left: 0px;
    margin-right: 0px;
    padding: 20px;
    letter-spacing: 0.6vw;
    text-transform: uppercase;
    font-size: 1em;
  }

  .navbar-nav a:hover{ 
    border-bottom: 0px transparent;
  }

  .hero-txt {
    position: relative;
    bottom: -150px;
    font-size: 15px;
  }

  .hero-title {
    position: relative;
    bottom: -150px;
  }
  
  .navbar-brand .unapic {
    width: 110px;
    height: 110px;
    position: relative;
    left: 10px;
  }  
  
  .lightdark-mode {
    margin-left: 300px;
  }
}

.unapic {
  width: 120px;
  height: 120px;
  position: relative;
  left: 90px;
}

.lightdark-mode {
  position: relative;
  left: -150px;
  color: black;
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(70, 70, 70);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus {
  box-shadow: 0 0 1px #ccc;
}
input:checked + .slider:before {
  background-color: #00B0FF;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);

}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.dropdown {
  float: right;
  position: relative;
  top: 0.4em;
  font-size: 1.1em;
}

.dropdown a:hover {
  color: black;
}

#dropdown_btn:hover {
  color: white;
}

#dropdown_btn a {
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 7px;
  text-decoration: none;
  font-size: inherit;
}

.dropdown .dropbtn {
  border: none;
  outline: none;
  color: white;
  font-weight: 800;
  font-size: 1.1em;
  background-color: inherit;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 7px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #FAFBFC;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  width: 1200px;
  padding-top: 0.6em;
}

.dropdown-content a {
  float: none;
  color: black;
  line-height: 3.5em;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0em;
  margin-top: -0.5em;
}

.dropdown-content a:after {
  display: block;
  top: 1.5em;
  position: relative;
  width: 87%;
  float: right;
}

.tutoring-nav a:after {
  width: 85%;
}

.dropdown-content a:hover {
  background-color: #FAFBFC;
  color: var(--primary);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.tutoring-nav a {
  font-size: 0.85em;
}

.mobile-item {
  display: none;
}

@media screen and (max-width:940px) {
  .dropdown .dropbtn {
    margin-right: 0;
    font-size: 0.9em;
  }
  

  .mobile-item {
    display: block;
  }

  #serviceslink {
    position: relative;
    top: -0.6em;
    left: 0.5em;
  }

  .dropdown-content {
    width: 300px;
    left: -8em;
  }

  .dropdown-content a {
    line-height: 1em;
    margin-bottom: 0em;
  }

  .navbar-nav a:after {
    display: none;
  }
}